@keyframes fade-in__first{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes rotate{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

@keyframes heart{
    0{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
@keyframes button{
    0{
        transform: scale(1);
    }
    50%{
        transform: scale(1.056);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes box-bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-35px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}
@keyframes box-bounce__loader {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-100px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

@keyframes loader-bounce {
	0% {transform: translateY(0);
		-webkit-animation-timing-function: ease-in;
	}
	40% {}
	50% {transform: translateY(140px);
		height: 140px;
		-webkit-animation-timing-function: ease-out;
	}
	55% {transform: translateY(160px); height: 120px; 
		-webkit-animation-timing-function: ease-in;}
	65% {transform: translateY(120px); height: 140px; 
		-webkit-animation-timing-function: ease-out;}
	95% {
		transform: translateY(0);		
		-webkit-animation-timing-function: ease-in;
	}
	100% {transform: translateY(0);
		-webkit-animation-timing-function: ease-in;
	}
}

@keyframes hand{
    0%{ 
        transform: translate(0,0);
    }
    16% {
        transform: translateX(40%);
    }
    32% {
        transform: translate(50%, -40%);
    }
    50% {
        transform: translate(0,0);
    }
}
@keyframes hand-2{
    0%{ 
        transform: translate(0,0);
    }
    16% {
        transform: translate(-20%,-45%);
    }
    32% {
        transform: translate(45%,-45%);
    }
    50% {
        transform: translate(0,0);
    }
}
@keyframes circle-1{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    51%{
        opacity: 1;
    }
    87%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}
@keyframes circle-2{
    0%{
        opacity: 0;
    }
    55%{
        opacity: 0;
    }
    56%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}
