@mixin width-320{
    @media screen and (width: 320px){
        @content
    }
}
@mixin ie11{
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
        @content  
     }
}
@mixin width-321-up {
    @media screen and (min-width: 321px) {
        @content
    }
}
@mixin  width-376-up{
    @media screen and (min-width: 376px){
        @content
    }
} 
@mixin width-414-up {
    @media screen and (min-width: 414px) {
        @content
    }
}
@mixin width-481-up {
    @media screen and (min-width: 481px){
        @content
    }
}
@mixin landscape{
    @media (min-width: 481px) and (max-width: 992px) and (orientation: landscape){
        @content
    }
}
@mixin width-768-up {
    @media screen and (min-width: 768px){
        @content
    }
}
@mixin width-768-down {
    @media screen and (max-width: 768px){
        @content
    }
}
@mixin width-992-up {
    @media screen and (min-width: 992px){
        @content
    }
}
@mixin width-960-down {
    @media screen and (max-width: 960px){
        @content
    }
}
@mixin width-1024-up {
    @media screen and (min-width: 1024px){
        @content
    }
}
@mixin width-1188-up {
    @media screen and (min-width: 1188px){
        @content
    }
}
@mixin width-1280-up{
    @media screen and (min-width: 1280px) {
        @content
    }
}
@mixin width-1366-up {
    @media screen and (min-width: 1366px){
        @content
    }
}
@mixin width-1441-up{
    @media screen and (min-width: 1441px) {
        @content
    }
}
@mixin width-1680-up{
    @media screen and (min-width: 1680px) {
        @content
    }
}




