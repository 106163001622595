@import 'variables';
@import 'mixins';
@import 'media';
@import 'keyframes';

// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }

body,
html{
    height: 100%;
}
body{
    background: #333333;
    font-family: 'Lato', sans-serif;
    position: relative;
}

// navbar css start
.logo-container{
    @include width-768-up{
        margin-top: 0;
    }
    .logo{
        border-radius: 0;
        border: none;
        @include width-768-up{
            width: 100px;
        }
        @include width-1024-up{
            margin-top: 10px;
        }
    }
}
.navbar{
    position: fixed;
    width: 100%;
    transition: all 0.2s ease-in-out;
    z-index: 9;
    @include width-1024-up{
        background-color: #f37188;
    }
    @include width-1366-up{
        padding-bottom: 0;
        background-color: #f37188;
    }
    .navbar-header{
        margin-left: 0px !important;
        margin-right: 0px !important;
        @include w-900-up{
            margin-left: -15px;
            margin-right: -15px;
        }
        .navbar-toggle{
            width: 45px;
            margin: 0;
            margin-top: 5px;
            padding-right: 0;
            @include width-768-up{
                padding: 0;
            }
        }
    }
    &.--active{
        background-color: rgba(243,113,136,1);
    }
}
.navbar .navbar-nav > li > a.btn-danger, .btn-danger{
    @include width-1024-up{
        color: #ffffff;
    }
}
li.desktop-links{
    display: none;
    @include width-1024-up{
        display: block;
        margin-right: 25px;
        a{
            display: inline-block;
            padding: 10px 5px !important;
            margin: 10px 3px !important;
        }
    }
    &.--language{
        margin-right: 25px;
        span{
            color: #FFFFFF;
        }
        a{
            display: inline-block;
            padding: 13px 0px !important;
            margin: 10px 3px !important;
        }
    }
}
.bottom_item{
    margin-top: 5% !important;
    @include width-1024-up{
        display: none !important;
    }
    a{
        display: inline-block !important;
        margin-right: 15px !important;
        &.mr-0{
            margin-right: 0 !important;
        }
    }

}
#menu-button__path{
    fill: #ffffff;
}
body > .navbar-collapse{
    &:after{
        background-color: #333333;
    }
    .btn-download{
        color: #FFFFFF !important;
        background-color: #7AC27A !important;
        border-color: #7AC27A !important;
        opacity: 1;
        filter: alpha(opacity=100);
        border-radius: 20px;
        display: inline-block;
        margin: 15px 0px !important;
        padding: 0.5rem 4rem !important;
    }
}
li.active .navbar-link{
    &:before{
        width:51%
    }
    &:after{
        width:51%
    }
}
.navbar-link{
    color: #ffffff;
    text-transform: uppercase;
    &:before{
        content: " ";
        position: absolute;
        left: 0%;
        bottom: 5px;
        height: 2px;
        width: 0%;
        background-color: #fff;
        transition: width 0.3s ease-in-out;
      }
      &:after{
        content: " ";
        position: absolute;
        right: 0%;
        bottom: 5px;
        height: 2px;
        width: 0%;
        background-color: #fff;
        transition: width 0.3s ease-in-out;
      }
      
      &:hover,&:focus,&.active{
        &:before{
          width:51%
        }
        &:after{
          width:51%
        }
      }
      @include width-768-up{
          font-size: 12px;
      }
      @include width-1366-up{
          font-size: 16px;
      }
}

#android-icon,
#android-icon-m{
    .android-icon,
    .android-icon-m{
        transition: all 0.2s ease-in-out;
    }
    &:hover{
        .android-icon,
        .android-icon-m{
            fill: #A4C639;
        }
    }
}
#apple-icon,
#apple-icon-m{
    .apple-icon,
    .apple-icon-m{
        transition: all 0.2s ease-in-out;
    }
    &:hover{
        .apple-icon,
        .apple-icon-m{
            fill: #aaa;
        }
    }
}

// loader

#loader{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../img/images/4like_pozadina.svg');
    background-color: #fff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    svg{
        width: 250px;
        animation: box-bounce__loader;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
    }
    &.hidden{
        display: none;
    }
}

// global
.container{
    @media(max-width: 480px){
        width: 300px;
    }
    @include width-481-up{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    @include landscape{
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
}
section{
    // @include width-1024-up{
        background-color: #FFFFFF;
    // }
}
section > .container{
    padding: 30px 0px 0px 0px;
    &.--no-padding{
        padding-top: 0px;
    }
    @include width-1024-up{
        padding: 80px 0px 0px 0px;
    }
    @include width-1366-up{
        padding: 80px 15px 0px 15px;
    }
}
.section-title{
    font-size: 20px;
    color: $textGrey;
    font-family: 'PT Serif', serif;
    font-weight: 600;
    text-transform: uppercase;
    &.pink{
        color: #f37188;
    }
    @include width-768-up{
        font-size: 30px;
    }
}
.number{
    font-family: 'PT Serif', serif;
    font-size: 40px;
    font-weight: 600;
    padding-right: 15px;
    color: rgba(86,86,86, 0.3)

}
.icon{
    margin-right: 20px;
    svg{
        @include width-481-up{
            width: 20px;
            height: 20px;
        }
        @include width-768-up{
            width: 35px;
            height: 35px;
        }
        @include width-1366-up{
            width: 28px;
            height: 28px;
        }
        @include width-1441-up{
            width: 35px;
            height: 35px;
        }
    }
}
.text-content{
    font-size: 14px;
    @include width-768-up{
        font-size: 20px;
    }
}

.green_btn{
    color: #FFFFFF !important;
    background-color: #7ac29a !important;
    border-color: #7ac29a !important;
    opacity: 1;
    border-radius: 20px;
    display: inline-block;
    margin: 15px 0px !important;
    padding: 1rem 2.5rem !important;
    text-transform: uppercase;
    transform: all 0.2s ease-in;
    &:hover{
        color: #7ac29a !important;
        background-color: #ffffff !important;
    }
    &.--slim{
        padding: 0.5rem 1.5rem !important;
    }
}

.heart{
    color: #cc2027;
    animation-name: heart;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}
.--cursor-pointer{
    cursor: pointer;
}

a.btn.btn-success.btn-fill{
    text-transform: uppercase;
    animation-name: button;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}
.btn-price{
    padding: 7px 18px;
    margin: 50px 0px;
}
// main section css start
.main-section{
    min-height: 100vh;
    background-image: url('../img/images/4like_background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    & > div{
        margin: 1.5rem 0rem;
    }
    &__logo{
        width: 130px;
        @include width-768-up{
            width: 230px;
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
    &__smiley{
        &.bounce{
            animation-name: box-bounce;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
        }
    }
    &__images-wrapper{
        &__text{
            font-size: 18px;
            color: #ffffff;
            text-transform: uppercase;
            text-align: center;
            @include width-768-up{
                font-size: 35px;
            }
            @include landscape{
                font-size: 30px;
            }
        }
        &__images{
            display: flex;
            justify-content: space-between;
            @include width-768-up{
                justify-content: center;
            }
            svg{
                flex: 0 0 18%;
                max-width: 18%;
                height: 55px;
                &:hover{
                    animation-name: box-bounce;
                    animation-duration: 1.5s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    animation-timing-function: ease-in-out;
                }
                @include landscape{
                    margin-right: 10px;
                }
                @include width-768-up{
                    flex: 0 0 15%;
                    max-width: 15%;
                }
                &.fade-in__first{
                    animation-name: fade-in__first;
                    animation-duration: 0.2;
                    animation-timing-function: ease-in;
                }
            }
        }
    }
    &__button-wrapper{
        &.-calculator{
            text-align: center;
        }
        a{
            animation: none !important;
        }
    }
}

// sub form
.sub-form{
    padding: 0px 40px;
    @include w-600-up{
        max-width: 500px;
    }
    @include w-1200-up{
        max-width: 665px;
        padding: 0px;
    }
    &__text{
        color: #ffffff;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.64px;
        text-align: center;
        padding: 0px 33px;
        @include w-600-up{
            font-size: 16px;
        }
        // @include w-1200-up{
        //     padding: 0px;
        //     font-size: 22px;
        // }
    }
    &__form{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 5px 5px;
        border: 1px solid #fff;
        border-radius: 20px;
        background-color: rgba(219,182,169, 0.24);
        @include w-600-up{
            padding: 8px;
            border-radius: 25px; 
        }
        // @include w-1200-up{
        //     padding: 12px 36px 12px 36px;
        //     border-radius: 45px;
        // }
    }
    &__input{
        background: none;
        border: none;
        padding-left: 11px;
        outline: none;
        color: #fff;
        font-size: 11px;
        flex: 0 0 70%;
        @include w-600-up{
            font-size: 16px;
        }
        // @include w-1200-up{
        //     font-size: 24px;
        // }
        &::placeholder{
            color: #fff;
            font-size: 11px;
            @include w-600-up{
                font-size: 16px;
            }
            // @include w-1200-up{
            //     font-size: 24px;
            // }
        }
    }
    &__button{
        background-color: #f37188;
        color: #fff;
        font-size: 8px;
        padding: 8px 21px;
        border-radius: 20px;
        border: none;
        text-transform: uppercase;
        @include w-600-up{
            font-size: 13px;
        }
        // @include w-1200-up{
        //     font-size: 18px;
        //     padding: 17px 47px;
        //     border-radius: 45px;
        // }
    }
    &__error-text{
        padding: 0px 15px;
        color: #fff;
        font-size: 9px;
        margin-top: 7px;
        display: block;
        @include w-600-up{
            font-size: 15px;
            margin-top: 10px;
        }
    }
}

// intro

.intro{
    &__main-img-wrapper{
        transform: translateY(-45%);
        margin-bottom: -27%;
        @include landscape{
            max-width: 300px;
            transform: translateY(-40%);
            margin: 0 auto;
            margin-bottom: -5%;
        }
        @include width-768-up{
            max-width: 400px;
            margin: 0 auto -15% auto;
        }
        @include width-1366-up{
            max-width: 900px;
            transform: translateY(-30%);
        }
    }
    &__main-img{
        background-image: url('../img/images/4like_monitortelefoni.png');
        background-size: cover;
        background-position: center;
        padding-bottom: calc(695 / 936 * 100%);
        &.-eng{
            background-image: url('../img/images/4like_monitortelefoni_eng.png');
        }
    }
    .section-title{
        @include width-768-up{
            text-align: center;
        }
    }
    &__text-wrapper{
        padding-top: 30px;
    }
    &__text{
        font-size: 14px;
        text-align: left;
        line-height: 19px;
        margin-bottom: 0;
        color: $textGrey;
        @include width-768-up{
            font-size: 20px;
            text-align: center;
            line-height: 40px;
        }
    }
}

// user options

.user-options{
    background-color: #fff8f0;
    // padding: 30px 0px;
    @include width-1366-up{
        .--second-title{
            text-align: left;
            margin-top: 0;
            margin-bottom: 45px;
        }
    }
    &__wrapper{
        display: flex;
        flex-flow: column nowrap;
        
        // transform: translateY(15%);
    }
    &__first{
        display: flex;
        flex-flow: column nowrap;
        // flex: 0 0 100%;
        // max-width: 100%;
        @include width-1366-up{
            flex-flow: row;
        }
        &__text-content{
            order: 1;
            @include width-1366-up{
                margin-top: 30px;
            }
        }
        &__text-content-wrapper{
            display: flex;
            flex-flow: row nowrap;
            @include landscape{
                margin-bottom: 15px;
            }
        }
        &__image-wrapper{
            width: 300px;
            margin: 0 auto;
            order: 2;
            padding: 40px 0px;
            @include width-1366-up{
                margin: 0 0 0 auto;
            }
            #your-options-hand{
                animation-name: hand;
                animation-duration: 8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                // animation-delay: 1.5s;
            }
            #your-options-1{
                opacity: 0;
                animation-name: circle-1;
                animation-duration: 8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
            }
            #your-options-2{
                opacity: 0;
                animation-name: circle-2;
                animation-duration: 8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
            }
        }
        &__image{
            background-image: url('../img/icons/4like_vase_mogucnosti.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding-bottom: calc(83 / 108 * 100%);
        }
    }
    &__second{
        display: flex;
        flex-flow: column nowrap;
        @include width-1366-up{
            flex-flow: row;
            margin-top: 30px;
        }
        &__text-content{
            order: 1;
            @include width-1366-up{
                order: 2;
                // margin-top: 30px;
            }
        }
        &__text-content-wrapper{
            display: flex;
            flex-flow: row nowrap;
            @include landscape{
                margin-bottom: 15px;
            }
        }
        &__image-wrapper{
            width: 300px;
            margin: 0 auto;
            order: 2;
            padding: 40px 0px;
            
            @include width-1366-up{
                order: 1;
                padding: 0;
                margin: 0 auto 0 0;
            }
            #user-options-hand{
                animation-name: hand-2;
                animation-duration: 8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                // animation-delay: 1.5s;
            }
            #user-options-1{
                opacity: 0;
                animation-name: circle-1;
                animation-duration: 8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
            }
            #user-options-2{
                opacity: 0;
                animation-name: circle-2;
                animation-duration: 8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
            }
        }
        &__image{
            background-image: url('../img/icons/4like_mogucnosti_korisnika.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding-bottom: calc(83 / 108 * 100%);
            &.--en{
                background-image: url('../img/images/4like_mogucnosti_korisnika_eng.svg')
            }
        }
    }

}

// partners
.partners{
    .section-title{
        @include width-768-up{
            text-align: center;
        }
    }
    &__slider{
        padding: 78px 0px;
        .slick-track{
            display: flex;
            align-items: center;
        }
        &__item{
            &:focus{
                outline: none !important;
            }
        }
        &__img{
            width: auto;
            height: 80px;
            margin: 0 30px;
            @include width-768-down{
                height: 50px;
            }
        }
        .slick-prev,
        .slick-next{
            img{
                position: absolute;
                top: -50%;
            }
        }
        .slick-prev img{
            left: -100%;
            right: unset;
        }
        .slick-next img{
            right: -100%;
            left: unset;
        }
    }
}
// create and implement

.create-and-implement{
    // padding: 30px 0px;
    .title-wrapper{
        position: relative;
        .section-title{
            // padding-left: 25px;
            font-size: 16px;
            color: #f37188;
            position: relative;
            z-index: 3;
            @include width-768-up{
                font-size: 25px;
            }
        }
    }
    &__wrapper{
        @include w-900-up{
            display: flex;
            flex-flow: row;
            justify-content: space-between;
        }
    }
    &__text{
        font-size: 14px;
        line-height: 24px;
        padding-top: 25px;
        @include width-768-up{
            font-size: 20px;
        }
        @include w-900-up{
            flex: 0 0 60%;
            max-width: 60%;
            line-height: 30px;
        }
    }
    &__image{
        display: none;
        @include w-900-up{
            display: block;
            width: 260px;
            &.-spec-width{
                width: 200px
            }
        }
    }
    &.--stripped{
        background-color: #fff8f0;
        .number{
            z-index: 1;
        }
    }
    &.--reverse .title-wrapper{
        margin-bottom: 25px;

    }
    &.--reverse &__text{
        padding-top: 0;
        @include width-1366-up{
            order: 2;
            margin-top: 0;
            .title-wrapper{
                margin-bottom: 25px;
            }
        }
    }
    &.--reverse &__image{
        @include width-1366-up{
            order: 1;
        }
    }
}

// multichannel marketing

.multichannel-marketing{
    .container{
        @include width-1366-up{
            padding: 0px 15px;
        }
    }
    background-color: #f37188;
    // padding: 30px 0px;
    .section-title{
        @include width-768-up{
            text-align: center;
        }
        @include width-1366-up{
            padding: 30px 0px;
        }
    }
    &__img-wrapper{
        padding: 30px 0px;
        text-align: center;
    }
    &__image{
        max-width: 80px;
        @include width-768-up{
            max-width: 160px;
        }
    }
    &__text{
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        @include width-768-up{
            font-size: 20px;
        }
        @include width-1366-up{
            padding: 30px 0px;
        }
    }
}


// service-rating

.service-rating{
    // padding: 60px 0px 60px 0px;
    &__image-wrapper{
        padding: 30px 10px 0px 10px;
        @include width-768-up{
        padding: 60px 10px 0px 10px;
        }
    }
    .rotate{
        transform-box: fill-box;
        transform-origin: 50% 50%;
        animation-name: rotate;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        animation-timing-function: linear;
    }
    &__image{
        @include landscape{
            padding: 40px;
        }
    }
    #service-rating__mobile-svg{
        @include width-768-up{
            display: none;
        }
        
    }
    #service-rating__desktop-svg{
        display: none;
        @include width-768-up{
            display: block;
        }
    }
}

// q-and-a

.q-and-a{
    // padding: 60px 0px;
    background-color: #ffffff;
    // .container{
    //     padding: 0px;
    // }
    .section-wrapper{
        margin-top: 60px;
        @include width-768-up{
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
    &__wrapper{
        background-color: #fff8f0;
        padding: 10px;
        margin-top: 25px;
        overflow: hidden;
        cursor: pointer;
        @include width-768-up{
            max-height: 100px;
            transition: all 0.3s ease-in-out;
            
        }
        @include width-1366-up{
            flex: 0 0 45%;
            max-width: 45%;
        }
        &.--open{
            // @include width-768-up{
                max-height: 1000px !important;
            // }
            .q-and-a__text{
                max-height: 200px !important;
                transition: all 0.3s ease-in-out;
                opacity: 1;
                padding: 15px;
                @include width-768-up{
                    max-height: 500px;
                }
            }
            .q-and-a__caret{
                svg{
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__title{
        margin-bottom: 0; 
        display: inline-block;
        max-width: 90%;
        // text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        @include width-768-up{
            font-size: 22px;
        }
        @include width-1366-up{
            font-size: 15px
        }
    }
    &__caret{
        float: right;
        max-width: 10%;
        svg{
            transition: all 0.2s ease-in-out;
        }
    }
    &__text{
        max-height: 0;
        font-size: 14px;
        padding: 15px;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        opacity: 0;
        transition: all 0.3s ease-in;
        @include width-768-up{
            font-size: 20px;
        }
        @include width-1366-up{
            font-size: 16px;
        }
    }
}

// try it


.try-it{
    // padding: 30px 0px;
    @include width-1024-up{
        // padding: 60px 0px;
    }
    background-color: #f37188;
    &__image-wrapper{
        width: 100%;
        padding: 60px 0px;
        @include width-768-up{
            text-align: center;
        }
        @include landscape{
            width: 50%;
            margin: 0 auto;
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
    &__btn-wrapper{
        text-align: center;
        @include width-1024-up{
            padding: 30px 0px;
        }
    }
    .container.--mobile{
        @include width-1366-up{
            display: none;
        }
    }
    .container.--desktop{
        display: none;
        @include width-1366-up{
            display: block;
        }
    }
    &__wrapper{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .try-it__image-wrapper{
            flex: 0 0 40%;
            max-width: 40%;
            padding: 0;
        }
    }
}

// packages

.packages{
    background-color: #fff8f0;
    // padding: 60px 0px;
    &__packages-wrapper{
        margin-top: 30px;
        @include width-768-up{
            display: flex;
            flex-flow: row;
            justify-content: space-between;
        }
        // @include width-1024-up{
        //     margin
        // }
    }
    &__package-wrapper-outer{
        // margin-bottom: 15px;
        @include width-768-up{
            flex: 0 0 30%;
            max-width: 30%;
        }
        @include width-1024-up{
            // margin-bottom: 30px;
        }
    }
    &__package-wrapper{
        // border-style : Solid;
        // border-color : #E6E6E6;
        // border-color : rgba(230, 230, 230, 1);
        // border-width : 1px;
        background-color: #fff;
        max-height: 600px;
        transition: all 0.3s ease-in;
        overflow: hidden;
       
        &:hover{
            .packages__image-wrapper > img{
                animation-name: box-bounce;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;
            }
        }
        
        &.--highlight{
            background-color: #f8a793;
            .packages__package-title,
            .packages__list-item{
                color: #ffffff;
                &.--greyed-out{
                    color: rgba(128,130,129,0.26);
                }
                &__icon{
                    &.--no{
                        svg{
                            fill: rgba(243,113,136,0.26)
                        }
                    }
                }
            }

        }
        &.--open{
            max-height: 1000px;
            .packages__footer-icon{
                background-image: url('../img/icons/4like_minus.svg');
            }
            .packages__list-wrapper{
                max-height: 1000px;
            }
        }
    }
    &__image-wrapper{
        // background-color: #ffffff;
        padding: 30px;
        img{
            max-width: 150px;
            height: 202px;
            margin: 0 auto;
            display: block;
            @include width-1024-up{
                height: 202px;
            }
        }
    }
    &__package-title{
        background-color: inherit;
        font-family: 'PT Serif', serif;
        font-size: 20px;
        color: $textGrey;
        padding: 30px 0px;
        text-transform: uppercase;
        text-align: center;
        @include width-768-up{
            font-size: 28px;
        }
    }
    &__list-wrapper{
        max-height: 100px;
        overflow: hidden;
        text-align: center;
        transition: all 0.3s ease-in;
        
    }
    &__details-list{
        padding: 0px;
        padding-bottom: 15px;
        margin: 0;
        text-align: center;
    }
    &__list-item{
        padding: 5px 0px;
        padding-left: 3rem;
        font-size: 16px;
        color: $textGrey;
        list-style-type: none;
        text-align: left;
        @include width-1366-up{
            padding-left: 5rem;
        }
        &.--greyed-out{
            color: rgba(128,130,129,0.46);
        }
        &__icon{
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: 1rem;
            &.--no{
                svg{
                    fill: rgba(243,113,136,0.46) !important;
                }
            }
        }
    }
    &__footer{
        padding: 15px 0px;
        background-color: #ffffff;
        cursor: pointer;
        &.--no-open{
            .packages__footer-icon{
                background-image: none;
            }
        }
    }
    &__footer-icon{
        width: 35px;
        height: 35px;
        background-image: url('../img/icons/4like_plus.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0 auto;
    }
    &__order-link{
        text-align: center;
        padding: 20px;
        &.--no-padding-bottom{
            padding-bottom: 0;
        }
        @include width-768-up{
            padding-bottom: 0;
        }
        .btn{
            margin-bottom: 0;
        }
    }
}

// package exaple title

.package-examples-title{
    background-color: #f37188;
    &__title{
        font-family: 'PT Serif', serif;
        text-transform: uppercase;
        font-weight: 600;
    }
    &__text{
        font-family: 'PT Serif', serif;
        text-transform: uppercase;
    }
}

// package examples

.package-examples{
    padding: 60px;
    position: relative;
    @include width-768-down{
        padding: 60px 0px 60px 0px;
    }
    @include w-1400-up{
        padding: 120px;
    }
    &__wrapper{
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 60px;
        @include width-768-up{
            justify-content: space-between;
        }
    }
    &__package-outer{
        position: relative;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 60px 00px 30px 0px;
        &:first-child{
            background-color: #fffcf5;
        }
        @include width-768-up{
            flex: 0 0 49%;
            max-width: 49%;
            box-shadow: 5px 5px 30px 1px rgba(214,214,214,0.5);
        }
        &.--stripped{
            background-color: #fff8f0;
        }
    }
    &__package-inner{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include width-768-up{
            height: 100%;
        }
    }
    &__title{
        color: #f37188;
        font-family: 'PT Serif', serif;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0;
    }
    &__text-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 80px;
        height: auto;
        padding: 0px 15px;
        @include width-768-up{
            height: 500px;
        }
        @include width-1366-up{
            padding: 0px 30px;
        }
    }
    &__text{
        // font-size: 18px;
    }
    &-img{
        max-width: 280px;
        margin-top: 30px;
        &.--wide-img{
            max-width: 400px;
        }
    }
    &__label-img{
        position: absolute;
        width: 100px;
        top: 0;
        right: 0;
        @include width-768-up{
            width: 130px;
        }
        @include width-1680-up{
            width: 150px;
        }
    }
}

// calculator
.calculator{
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(82,74,86,0.54);
    z-index: 20;
    @include width-768-down{
        padding: 10px;
        // height: auto;
    }
    &__wrapper{
        display: none;
        position: relative;
        box-shadow: 5px 5px 30px 1px rgba(214,214,214,0.5);
        background-color: #ffffff;
    }
    &__header{
        padding: 30px 20px 0px 20px;
        @include w-1800-up{
            padding: 70px 96px 0px 93px;
        }
    }
    &__title{
        color: #f37188;
        font-family: "PT Serif", serif;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        margin: 0px;
        @include w-1800-up{
            font-size: 35px;
        }
    }
    &__subtitle{
        text-transform: uppercase;
        color: #cccccc;
        font-family: "PT Serif", serif;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin: 0px;
        @include w-1800-up{
            font-size: 30px;
        }
    }
    &__select-wrapper{
        padding-top: 20px;
        display: flex;
        @include width-768-down{
            flex-flow: row wrap;
        }
        @include w-1800-up{
            padding-top: 49px;

        }
    }
    &__select-item{
        // flex: 1 0 284px;
        @include width-768-down{
            flex: 0 0 100%;
        }
        &:first-child{
            margin-right: 183px;
            @include width-768-down{
                margin-right: 0px;
            }
        }
        .btn{
            display: flex;
            width: 100%;
            text-align: left;
            justify-content: space-between;
            align-items: center;
        }
        .form-group{
            margin-bottom: 0px;
        }
    }
    &__body{
        background-color: #fff8f0;
        display: flex;
        padding-top: 0px;
        padding-bottom: 16px;
        flex-direction: column;
        align-items: center;
        @include w-600-up{
            padding-top: 18px;
            padding-bottom: 24px;
        }
        @include width-768-down{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    &__body-title{
        color: #f37188;
        font-family: "PT Serif";
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    &__result{
        background-color: #ffffff;
        border-radius: 20px;
        padding: 7px 18px;
        min-width: 306px;
        color: #66615B;
        font-family: 'Lato', sans-serif;
        font-size: 22px;
        font-weight: 400; 
        text-align: center;
    }
    &__error-text{
        color: #f37188;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400; 
        transition: all 0.1s ease-out;
        padding-top: 20px;
        opacity: 0;
        display: block;
        @include width-768-down{
            padding-top: 10px;
        }
        &.-active{
            opacity: 1;
        }
        &.-info-text{
            color: #666666;
            font-family: Lato;
            font-size: 15px;
            font-style: italic;
            opacity: 1;
            @include width-768-down{
                font-size: 10px;
                text-align: center;
            }
        }
        @include width-768-down{
            font-size: 10px;
        }
    }
    &__input{
        border: 2px solid #66615B !important;
        border-radius: 20px;
        &::placeholder{
            color: #66615B;
        }
    }
    .btn-price{
        
    }
    &__close-icon{
        position: absolute;
        right: 19px;
        top: 19px;
        cursor: pointer;
    }
    #all-in-one-select{
        display: block !important;
    }
    .btn-price{
        margin: 25px 0px;
        font-size: 10px;
        @include w-600-up{
            font-size: 16px;
        }
        @include width-768-down{
            margin: 10px 0px;
        }
        @include w-1800-up{
            margin: 50px 0px;
        }
    }
}

// footer
.footer{
    padding: 80px 0px 60px 0px;
    background-color: #fff8f0;
    &__wrapper{
        max-width: 300px;
        margin: 0 auto;
        @include width-768-up{
            max-width: 700px;
        }
    }
    &__title{
        text-transform: uppercase;
        font-size: 16px;
        color: $textGrey;
        text-align: center;
        @include width-768-up{
            font-size: 22px;
        }
        &.--small{
            font-family: 'PT Serif', serif;
            font-weight: 600;
            font-size: 14px;
            @include width-768-up{
                font-size: 18px;
            }
        }
    }
    &__icons-wrapper{
        padding: 30px 0px;
        text-align: center;
    }
    &__icon{
        max-width: 130px;
        height: auto;
        margin: 0px 5px;
        @include width-768-up{
            max-width: 200px;
        }
    }
    &__image-wrapper{
        padding: 30px 0px;
        text-align: center;
    }
    &__image{
        max-width: 100px;
        height: auto;
        @include width-768-up{
            max-width: 200px;
        }
    }
    &__social-icons-wrapper{
        text-align: center;
    }
    &__social-icons__phone-wrapper{
        padding: 30px 0px;
        text-align: center;
    }
    &__social-icons__phone{
        a{
            color: $textGrey;
        }
    }
    &__social-icon{
        max-width: 50px;
        height: auto;
        margin: 0px 7px;
        @include width-768-up{
            margin: 0px;
            max-width: 60px;
        }
    }
}

// to top

.to-top{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 19;
    transition: all 0.1s ease-in-out;
    &.--banner__on-page{
        bottom: 10rem;
    }
    #to-top{
        width: 35px;
        height: auto;
        @include width-768-up{
            width: 45px;
        }
    }
    @include width-1188-up{
        bottom: 2rem;
    }
}

// helper classes
.mr-0{
    margin-right: 0px !important;
}
.--text-center{
    text-align: center;
}
.--text-white{
    color: #ffffff;
}