@mixin w-600-up {
    @media (min-width: 600px){
        @content;
    }
}
@mixin w-900-up {
    @media (min-width: 900px){
        @content;
    }
}
@mixin w-1200-up {
    @media (min-width: 1200px){
        @content;
    }
}
@mixin w-1400-up {
    @media (min-width: 1400px){
        @content;
    }
}
@mixin w-1800-up {
    @media (min-width: 1800px){
        @content;
    }
}